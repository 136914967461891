//variables
$primaryColor: #f44336;

$statusOk: #4caf50;
$statusWarning: #ffc107;
$statusError: #f44336;
$statusOff: #9e9e9e;
$colorTextBlack: rgb(50, 50, 50);

.blueprint {
    .point {
        border: 0.2em dashed $statusOff;

        .status {
            display: inline-block;
            width: 0.5em;
            height: 0.5em;
            border-radius: 50%;
            border: 0.05em solid;
            margin-right: 0.1em;
            background-color: #9c27b0;

            &--on {
                background-color: $statusOk;
            }

            &--off {
                background-color: $statusOff;
            }

            &--warning {
                background-color: $statusWarning;
            }

            &--error {
                background-color: $statusError;

                animation: blinker 1s linear infinite;

                @keyframes blinker {
                    50% {
                        opacity: 0.2;
                    }
                }
            }
        }

        .wrapper__point_title {
            position: absolute;
            top: -3em;
        }

        .point_content {
            position: relative;
            height: 100%;

            &--on {
                background-color: rgba($statusOk, 0.1);
            }

            &--off {
                background-color: rgba($statusOff, 0.1);
            }

            &--warning {
                background-color: rgba($statusWarning, 0.1);
            }

            &--error {
                background-color: rgba($statusError, 0.1);
            }

            & > div {
                width: 100%;
            }
        }

        .wrapper__info_data {
            display: flex;
            height: calc(100% - 3em);

            & > div {
                max-width: 100%;
            }

            &--column {
                .info_data__column {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                }

                & > div {
                    flex-grow: 1;
                    width: calc(50% - 2em);
                }
            }
        }

        .info_product {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0.5em;
            width: calc(100% - 1em);

            span {
                padding: 0.1em 0.2em;
                flex-grow: 1;
                text-align: center;
            }
        }

        .info_batch,
        .info_lastStop {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: calc(100% - 1em);
            text-align: center;
            margin: 0.5em;
            min-height: 3.5rem;
            max-height: 3.5rem;

            span {
                display: inline-block;
                white-space: nowrap;
                margin: 0 0.2em;

                &.highlight {
                    color: $primaryColor;
                }
            }
        }

        .info_batch span {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .info_lastStop {
            text-align: justify;

            & > div {
                display: flex;
                white-space: nowrap;
                width: 100%;
                justify-content: space-between;

                & > span:first-child {
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .info_clients_pallets,
        .info_direct_employees {
            font-size: 0.8em;
            width: calc(100% - 1em);
            text-align: center;
            margin: 0 0.5em;
            max-height: calc(100% - 4em);
            overflow-y: scroll;
            box-shadow: inset 0 0 0.5em lightgray;

            & > div {
                display: flex;
                white-space: nowrap;
                width: 100%;
                justify-content: space-between;

                & span {
                    padding: 0.1em 0.2em;
                }
            }

            &__label {
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &__value {
                color: $primaryColor;

                &--not-started {
                    color: $statusOff;
                }

                &--finished {
                    color: $statusOk;
                }
            }
        }
    }
}

.modal_list {
    .title_task {
        color: $primaryColor;
        font-size: 0.8rem;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 200;
        line-height: 1.6;
        letter-spacing: 0.0075em;
        border-bottom: solid $primaryColor 2px;
    }

    .row_list {
        margin-top: 1em;

        .info_employees {
            &__label {
                text-overflow: ellipsis;
                overflow: hidden;
                color: rgba(0, 0, 0, 0.87);
                font-size: 0.8rem;
                font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                font-weight: 200;
                line-height: 1.6;
                letter-spacing: 0.0075em;
            }

            &__value {
                color: $primaryColor;
                font-size: 0.8rem;
                font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                font-weight: 200;
                line-height: 1.6;
                letter-spacing: 0.0075em;
            }
        }
    }
}
